// scss-docs-start import-stack
// Configuration
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/utilities";

// Layout & components
@import "../../../node_modules/bootstrap/scss/root";
@import "../../../node_modules/bootstrap/scss/reboot";
@import "../../../node_modules/bootstrap/scss/type";
@import "../../../node_modules/bootstrap/scss/close";
@import "../../../node_modules/bootstrap/scss/containers";
@import "../../../node_modules/bootstrap/scss/grid";
@import "../../../node_modules/bootstrap/scss/buttons";
@import "../../../node_modules/bootstrap/scss/transitions";
@import "../../../node_modules/bootstrap/scss/dropdown";
@import "../../../node_modules/bootstrap/scss/forms";
@import "../../../node_modules/bootstrap/scss/nav";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/navbar";
@import "../../../node_modules/bootstrap/scss/accordion";
@import "../../../node_modules/bootstrap/scss/carousel";
@import "../../../node_modules/bootstrap/scss/modal";
@import "../../../node_modules/bootstrap/scss/spinners";

// Helpers
@import "../../../node_modules/bootstrap/scss/helpers";

// Utilities
@import "../../../node_modules/bootstrap/scss/utilities/api";
// scss-docs-end import-stack

@import "../libs/slick";
@import "../libs/slick-theme";
@import "../elements/search-bar";
@import "../elements/variables";
@import "../elements/overrides";
@import "../elements/mega-menu";
@import "../elements/footer";

.accordion-button:not(.collapsed),
.accordion-button:focus {
  color: $lightGray !important;
  background-color: $navy !important;
  border: none;
}

.flex-4 .accordion {
  ol, p {
    font-size: 1.2rem;
    line-height: 1.5rem;
  }
}

span.play {
  position: absolute;
  top: calc(50%);
  left: calc(50%);
  transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  height: 5rem;
  width: 5rem;
  background: url(../assets/images/play.png) no-repeat center center;
  background-size: cover;
}

.solutions-page-header {
  background-size: contain !important;
  background-position: bottom left;
  background-repeat: no-repeat;
}

.video-container {
  aspect-ratio: 16/9;
}

.office-logo {
  cursor: pointer;
  filter: grayscale(1) !important;
  &:hover {
    filter: none !important;
    transition: filter .5s;
  }
}

.page-template-template-contact footer {
  margin-top: 0 !important;
}

.page-template-template-solution {
  .container-fluid {
    .container:nth-child(2) h2{
      @extend .text-gradient;
    }
  }
}

.page-template-template-flexible {
  .container-fluid {
    section:nth-child(2) h2{
      @extend .text-gradient;
    }
  }
}

#production-employees-modal a:hover {
  color: $navy !important;
  text-decoration: underline !important;
}

#bottom-corner-image {
  margin-bottom: -.8rem !important;
  text-align: right;
  img {
    width: 100px;
  }
}

.bg-gradient-teal-blue strong {
  color: white !important;
}

.accordion-button:after {
  background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>") !important;
}

#contact-options {
  cursor: pointer;
  > div {
    &:hover {
      transform: scale(1.02);
      transition: transform .2s;
    }
    transform: scale(1);
    transition: transform .2s;
  }
}



@media only screen and (max-width: 1180px) {
  #danger-zone {
    min-height: auto !important;
    div {
      text-align: center !important;
    }
  }
}

.flex-5 {
  &.image-card {
    img {
      max-width: 377px !important;
      max-height: 452px;
    }
  }
}

.single-post .header {
  @extend .bg-gradient-pearl-black;
}

ol {
  font-family: proxima-nova, sans-serif !important;
}

.page-id-324 strong,
.page-id-12350 strong,
li::marker {
	color: #007DF9;
}

.sgpb-content {
  padding: 3rem;
}

.sgpb-popup-close-button-3 {
  top:0 !important;
}

.course-card,
.class-card {
  scale:1;
  transition: all .3s;
  &:hover {
    scale:1.06;
    transition: all .3s;
  }
}

.page-template-financial-services,
.page-template-incentives-page {
 .accordion-button:after{
    background: none !important;
  }
  
  .accordion-button:not(.collapsed),
  .accordion-button:focus {
    background-color: #000000 !important;
    color: $pink !important;
  }
  
  .hs_submit .hs-button {
    @extend .bg-pink;
    @extend .fs-5;
    @extend .btn;
    @extend .text-white;
    @extend .fw-bold;
    @extend .ms-2;
    @extend .px-4;
  }
  
  .hs_email {
    .hs-input {
      @extend .form-control;
      @extend .fs-5;
    }
    label, ul.no-list {
      @extend .d-none;
    }
  } 
  
  .hs-form-private, .hbspt-form {
     @extend .d-flex; 
     @extend .align-items-center;
     @extend .justify-content-center;
  }

  .image-circle {
    width: 359px;
  }

  @include media-breakpoint-up(md) {
    .hs-input {
      width: 500px !important;
    }
  }

  @include media-breakpoint-down(sm) {
    .image-circle {
      width: 259px !important;
    }
  }

}